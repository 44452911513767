import React, { useState } from "react";
import Input from "../Additional/Input";
import Button from "../Additional/Button";
import CustomSelect from "../Additional/CustomSelect";

const AddUser = (props) => {

    const [login, setLogin] = useState();
    const [password, setPassword] = useState();
    const [group, setGroup] = useState();

    const addUser = () => {
        if(login && password && group.id){
            props.addUser(login, password, group.id);
            setLogin('');
            setPassword('');
        }else{
            alert('Заполните пустые поля');
        }
    }

    return(
        <>
            <div className='bg-bg-gradient px-6 py-8 mt-4 w-full rounded-2xl'>
                <h1 className='mb-4 text-2xl text-white'>Добавить пользователя</h1>

                <div className='flex gap-x-10 mb-6 sm:flex-col sm:items-start sm:gap-y-4'>
                    <Input data={login} setData={setLogin} type='text' name='Логин' />
                    <Input data={password} setData={setPassword} type='text' name='Пароль' />
                    <div className="flex flex-col">
                        <label className="text-light mb-2">Команда</label>
                        <CustomSelect options={props.groups} selectedOption={group?.name} onSelect={setGroup} label='Команда'/>
                    </div>
                </div>
                <Button text='Добавить' onClick={addUser}/>
            </div>
        </>
    )
}

export default AddUser;