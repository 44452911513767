import { useState, useRef, useEffect } from 'react';

const CustomSelect = ({ options, selectedOption, onSelect, label, formName, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    const returnValue = value ? option.value : option;
    if(formName){
      onSelect(formName, returnValue);
    }else{
      onSelect(returnValue);
    }
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false); 
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
    <div className="relative" ref={wrapperRef}>
      <div className={`absolute inset-0 rounded-2xl bg-gradient z-0`} />
      <div
        className={`bg-dark px-2 py-1 rounded-2xl cursor-pointer text-white relative z-10 m-[1px] flex justify-between items-center`}
        onClick={toggleDropdown}
      >
        {selectedOption ? selectedOption : label}
        <img src='/images/arrow.svg' alt='' width={17} height={17}/>
      </div>
      {isOpen && (
        <div className="absolute inline-block w-fit mt-[1px]">
            <div className="absolute inset-0 rounded-2xl bg-gradient" />
            <div className={`bg-bg-gradient rounded-2xl p-2 z-20 text-white relative m-[1px]`}>
                {options.map((option) => (
                <div
                key={option.id}
                className="py-1 px-6 cursor-pointer hover:text-lemon hover:bg-dark rounded-2xl w-full"
                onClick={() => handleSelect(option)}
                >
                {option.name}
                </div>
                ))}
            </div>
        </div>
        )}
    </div>
    </>
  );
};

export default CustomSelect;