import React, { useState, useEffect, useContext } from "react";
import WeekSelect from "../WeekSelect";
import { AuthContext } from "../../utils/AuthProvider";

const IncomesTable = (props) => {

	const [totalTeamSum, setTotalTeamSum] = useState(0);
    const [totalManagerSum, setTotalManagerSum] = useState(0);
    const {user} = useContext(AuthContext);;

    useEffect(() => {
        const calculateTotals = () => {
            let totalTeamSum = 0;
            let totalManagerSum = 0;
    
            props.incomes.forEach(item => {
                if (Array.isArray(item.Managers) && item.Managers.length > 0) {
                    const uniqueGroups = new Set(item.Managers.map(manager => manager.groupID));
                    const share = item.sum / uniqueGroups.size;
                    totalTeamSum += share;
    
                    item.Managers.forEach(manager => {
                        if (manager.groupID === user.groupID) {
                            const managerShare = item.sum * (manager.ManagerPercent.percent / 100);
                            totalManagerSum += managerShare;
                        }
                    });
                }
            });
    
            return { totalTeamSum, totalManagerSum };
        };
    
        const { totalTeamSum, totalManagerSum } = calculateTotals();
        setTotalTeamSum(totalTeamSum);
        setTotalManagerSum(totalManagerSum);
    }, [props.incomes]);

    return(
        <>
        <div className='bg-bg-gradient px-6 py-8 w-full rounded-2xl h-fit mt-4'>
			<h1 className='mb-4 text-2xl text-white'>Последние результаты</h1>
			<WeekSelect inputs={props.datePeriod} setInputs={props.setDatePeriod}/>
            <div className="overflow-x-auto">
			<table className='my-table mt-4'>
                <thead>
                <tr className='border-b-2 border-grey text-light'>
                    <th>Дата</th>
                    <th>Сумма</th>
                    <th>Менеджеры</th>
                    <th>Зарплата</th>
                    <th>Коментарий</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
				{props.incomes?.map((income, index) => {
                    const managers = income.Managers;

                    return (
                        <tr key={index} className='text-center text-white text-sm'>
                            <td className="sm:px-5 text-nowrap">{income.date}</td>
                            <td className='sm:px-5'><span className="bg-black text-xs py-1 px-2 rounded-2xl text-lemon mr-1">{income.exchange}</span>
                            {income.sum}</td>
                            <td>
                                {managers.map((manager, managerIndex) => (
                                    <div className='flex items-center pl-[30%] lg:pl-[20%] sm:px-5'>
                                        <span className="text-xs text-black bg-white px-2 py-1 rounded-2xl mr-2 font-medium">{manager.ManagerPercent.percent}%</span>
                                        <span key={managerIndex} className='block py-1 text-nowrap'>{manager.name}</span>
                                    </div>
                                ))}
                            </td>
                            <td className='sm:px-5'>
                                {managers.map((manager, managerIndex) => (
                                    <span key={managerIndex} className='block py-1'>$ {((income.sum / 100) * manager.ManagerPercent.percent).toFixed(0)}</span>
                                ))}
                            </td>
                            <td className='sm:px-5'>{income.comment}</td>
                            <td>
                                <img src='/images/delete.svg' alt="" width={30} className='cursor-pointer' onClick={() => props.deleteIncome(income)}/>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
			</table>
            </div>
		</div>
        <div className='w-full ml-auto mx-10 mt-4 mb-20'>
        <div className='bg-bg-gradient px-6 py-8 w-full rounded-2xl h-fit'>
            <h1 className='mb-4 text-2xl text-white'>Все результаты</h1>
            <div className="overflow-x-auto">
            <table className='my-table'>
                <thead>
                <tr className='border-b-2 border-grey text-light text-nowrap'>
                    <th className='xs:px-5'>Сумма</th>
                    <th className='xs:px-5'>Зарплатный фонд</th>
                    <th className='xs:px-5'>Руководители</th>
                    <th className='xs:px-5'>Менеджеры</th>
                </tr>
                </thead>
                <tbody className='text-sm text-white text-center'>
                    <td>$ {(totalTeamSum).toFixed(0)}</td>
                    <td>$ {(totalTeamSum/10 + totalManagerSum).toFixed(0)}</td>
                    <td>$ {(totalTeamSum/10).toFixed(0)}</td>
                    <td>$ {totalManagerSum.toFixed(0)}</td>
                </tbody>
            </table>
            </div>
        </div>
    </div>
    </>
    )
}

export default IncomesTable;