import React, { useEffect, useState, useContext } from "react";
import Header from "../components/Header";
import $api from "../http";
import WeekSelect from "../components/WeekSelect";
import Sidebar from "../components/Sidebar";
import Button from "../components/Additional/Button";
import CustomSelect from "../components/Additional/CustomSelect";
import { SidebarContext } from "../utils/SidebarProvider";

const Report = () => {

    const {menuActive} = useContext(SidebarContext);

    const [inputs, setInputs] = useState({
        dateRanges: [],
        group: ''
    });

    const [groups, setGroups] = useState([]);
    const [managers, setManagers] = useState([]);
    const [incomes, setIncomes] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [activeSide, setSide] = useState(1);
    const [managerEarnings, setManagerEarnings] = useState({});
    const [totalTeamSum, setTotalTeamSum] = useState(0);
    const [totalManagerSum, setTotalManagerSum] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState({
        USDT: 0,
        USD: 0
    });

    const [fondPercent, setFondPercent] = useState(25);

    const handleInputGroup = (value) => {
		setInputs(prevState => ({
			...prevState,
			'group': value
		}));
	};


    const getGroups = async () => {
        try{
            const response = await $api.get('/groups/get');
            setGroups(response.data);
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const getManagers = async () => {
        try{
            const response = await $api.get('/managers/get');
            setManagers(response.data);
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const getIncomes = async () => {
        try{
            const response = await $api.get('/income/get', {
                params: {
                    dateRanges: inputs.dateRanges,
                    groupID: inputs.group.id
                }
            });
            setIncomes(response.data);
        }catch(error){
            console.log(error.response.data.message);
        }
    }

    const getExpenses = async() => {
        try{
            const response = await $api.get('/expense/get', {
                params: {
                    dateRanges: inputs.dateRanges,
                    groupID: inputs.group.id
                }
            });
            setExpenses(response.data);
        }catch(error){
            alert(error.response.data.message);
        }
    }

    function calculateTotalExpenses(expenses, exchange) {
        return expenses.reduce((total, expense) => {
            if (expense.exchange === exchange) {
                return total + expense.sum;
            }
            return total;
        }, 0);
    }

    function calculateManagerEarnings(income) {
        const earnings = {};
    
        income.forEach(item => {
            const managers = item.Managers;
            const itemSum = item.sum;
    
            managers.forEach(manager => {
                if(manager.groupID === parseInt(inputs.group.id)){
                    const percent = parseFloat(manager.ManagerPercent.percent);
                    const earningsForManager = (itemSum * percent) / 100;
        
                    if(!earnings[manager.id]){
                        earnings[manager.id] = 0;
                    }
        
                    earnings[manager.id] += earningsForManager;
                }
            });
        });

        return earnings;
    }

    const findManagerById = (id) => {
        const manager = managers.find(manager => manager.id === id);
        return manager?.name;
    };

    const downloadFile = async () => {
        try{
            if(inputs.dateRanges.length > 0 && inputs.group.id){
                const response = await $api.post('/income/getFile', {
                    dateRanges: inputs.dateRanges,
                    groupID: inputs.group.id
                }, {
                    responseType: 'blob'
                });
    
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
    
                link.href = url;
                link.setAttribute('download', 'manager_earnings.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }else{
                alert('Заполните пустые поля');
            }
        }catch(error){
            console.error(error);
            alert('Произошла ошибка при загрузке файла');
        }
    }

    useEffect(() => {
        getGroups();
        getManagers();
    }, []);

    useEffect(() => {
        if(inputs.dateRanges.length > 0 && inputs.group.id){
            getIncomes();
            getExpenses();
        }
    }, [inputs]);

    useEffect(() => {
        setManagerEarnings(calculateManagerEarnings(incomes));
	}, [incomes]);

    useEffect(() => {
        const calculateTotals = () => {
            let totalTeamSum = 0;
            let totalManagerSum = 0;
    
            incomes.forEach(item => {
                if (Array.isArray(item.Managers) && item.Managers.length > 0) {
                    const uniqueGroups = new Set(item.Managers.map(manager => manager.groupID));
                    const share = item.sum / uniqueGroups.size;
                    totalTeamSum += share;
    
                    item.Managers.forEach(manager => {
                        if (manager.groupID === parseInt(inputs.group.id)) {
                            const managerShare = item.sum * (manager.ManagerPercent.percent / 100);
                            totalManagerSum += managerShare;
                        }
                    });
                }
            });
    
            return { totalTeamSum, totalManagerSum };
        };
    
        const { totalTeamSum, totalManagerSum } = calculateTotals();
        setTotalTeamSum(totalTeamSum);
        setTotalManagerSum(totalManagerSum);
    }, [incomes]);

    useEffect(() => {
        setTotalExpenses(prevState => ({
			...prevState,
			'USDT': calculateTotalExpenses(expenses, 'USDT'),
            'USD': calculateTotalExpenses(expenses, '$')
		}));
    }, [expenses])

    return(
        <>
            <Sidebar active='report'/>
            <Header />
            <div className={`ml-auto md:px-10 xs:px-5 ${menuActive ? 'w-4/5 2k:px-10' : 'w-full xxl:px-20'}`}>
            <div className='flex flex-col mx-auto max-w-[1400px]'>
                <div className='bg-bg-gradient flex mt-4 p-4 rounded-2xl h-fit justify-between items-center md:flex-col md:items-start md:gap-y-4'>
                    <div className='gap-x-10 flex md:flex-wrap md:gap-y-4'>
                        <WeekSelect inputs={inputs} setInputs={setInputs} className='mt-9'/>
                        <CustomSelect options={groups?.filter((elem) => elem.name !== 'Администратор')} selectedOption={inputs.group.name} onSelect={handleInputGroup} name='group' label='Команда'/>
                        <div className='flex items-center text-white'>
                            <label className="">ЗФ</label>
                            <div className="relative inline-block w-fit ml-2">
                                <div className="absolute inset-0 rounded-2xl bg-gradient" />
                                <input
                                type='number'
                                className="bg-dark px-2 py-1 rounded-2xl relative z-10 m-[1px] text-white w-[50px] text-center"
                                value={fondPercent}
                                onChange={(e) => setFondPercent(e.target.value)}
                                />
                            </div>
                            <label className="ml-2">%</label>
                        </div>
                    </div>
                    <Button text='Загрузить отчет' onClick={downloadFile}/>
                </div>

                <div className={`mt-4 flex text-center`}>
                    <h3 className={`w-1/2 border-b pb-2 cursor-pointer ${activeSide === 1 ? 'text-red border-red' : 'text-light border-light'}`} onClick={() => setSide(1)}>Результаты</h3>
                    <h3 className={`w-1/2 border-b pb-2 cursor-pointer ${activeSide === 2 ? 'text-red border-red' : 'text-light border-light'}`} onClick={() => setSide(2)}>Расходы и зарплата</h3>
                </div>

                {
                    activeSide === 1 ? 
                    <>
                    <div className='bg-bg-gradient px-6 py-8 w-full rounded-3xl h-fit mt-4'>
                        <h1 className='mb-4 text-2xl text-white'>Результаты за период</h1>
                        <div className="overflow-x-auto">
                        <table className='my-table'>
                            <thead>
                            <tr className='border-b-2 border-grey text-light'>
                                <th>Дата</th>
                                <th>Сумма</th>
                                <th>Менеджеры</th>
                                <th>Зарплата</th>
                                <th>Коментарий</th>
                            </tr>
                            </thead>
                            <tbody>
                            {incomes?.map((income, index) => {
                                const managers = income.Managers;

                                return (
                                <tr key={index} className='text-center text-white text-sm'>
                                    <td className="sm:px-5 text-nowrap">{income.date}</td>
                                    <td className='sm:px-5'>
                                        <span className="bg-black text-xs py-1 px-2 rounded-2xl text-lemon mr-1">{income.exchange}</span>
                                        {income.sum}</td>
                                    <td>
                                        {managers.map((manager, managerIndex) => (
                                            <div className='flex items-center pl-[30%] lg:pl-[20%] sm:px-5 text-nowrap'>
                                                <span className="text-xs text-black bg-white px-2 py-1 rounded-2xl mr-2 font-medium">{manager.ManagerPercent.percent}%</span>
                                                <span key={managerIndex} className='block py-1'>{findManagerById(parseInt(manager.id))}</span>
                                            </div>
                                        ))}
                                    </td>
                                    <td className='sm:px-5'>
                                        {managers.map((manager, managerIndex) => (
                                            <span key={managerIndex} className='block py-1'>$ {((income.sum / 100) * manager.ManagerPercent.percent).toFixed(0)}</span>
                                        ))}
                                    </td>
                                    <td className='sm:px-5'>{income.comment}</td>
                                </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        </div>
                    </div>

                    <div className='bg-bg-gradient px-6 py-8 w-full rounded-3xl h-fit mt-4 mb-20'>
                        <h1 className='mb-4 text-2xl text-white'>Все результаты</h1>
                        <div className="overflow-x-auto">
                        <table className='my-table'>
                            <thead>
                            <tr className='border-b-2 border-grey text-light text-nowrap'>
                                <th className='xs:px-5'>Сумма</th>
                                <th className='xs:px-5'>Зарплатный фонд</th>
                                <th className='xs:px-5'>Руководители</th>
                                <th className='xs:px-5'>Менеджеры</th>
                            </tr>
                            </thead>
                            <tbody className='text-sm text-white text-center'>
                                <td>$ {(totalTeamSum).toFixed(0)}</td>
                                <td>$ {(totalTeamSum * fondPercent / 100).toFixed(0)}</td>
                                <td>$ {(totalTeamSum/10).toFixed(0)}</td>
                                <td>$ {totalManagerSum.toFixed(0)}</td>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </> : 
                <>
                <div className='mt-4 flex gap-x-6 md:flex-col md:gap-y-4'>
                    <div className='bg-bg-gradient px-6 py-8 w-1/2 rounded-3xl h-fit md:w-full'>
                        <h1 className='mb-4 text-2xl text-white'>Расходы за период</h1>
                        <div className="overflow-x-auto">
                        <table className='my-table'>
                            <thead>
                            <tr className='border-b-2 border-grey text-light'>
                                <th>Дата</th>
                                <th>Сумма</th>
                                <th>Категория</th>
                            </tr>
                            </thead>
                            <tbody>
                            {expenses?.map((expense, index) => (
                                <tr key={index} className='text-center text-white text-sm'>
                                    <td className='xs:px-5 text-nowrap'>{expense.date}</td>
                                    <td className='xs:px-5'>
                                        <span className="bg-black text-xs py-1 px-2 rounded-2xl text-lemon mr-1">{expense.exchange}</span>
                                        {expense.sum}
                                    </td>
                                    <td className='xs:px-5'>{expense.ExpensesCat.name}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div className='bg-bg-gradient px-6 py-8 w-1/2 rounded-3xl h-fit md:w-full'>
                        <h1 className='mb-4 text-2xl text-white'>Зарплата за период</h1>
                        <table className='my-table'>
                            <thead>
                            <tr className='border-b-2 border-grey text-light'>
                                <th>Менеджер</th>
                                <th>Зарплата</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(managerEarnings).map(key => (
                                <tr key={key} className='text-center text-white text-sm'>
                                    <td>{findManagerById(parseInt(key))}</td>
                                    <td>{(managerEarnings[key]).toFixed(0)} $</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                    <div className='bg-bg-gradient px-6 py-8 w-full rounded-3xl h-fit flex justify-between mt-4 mb-20'>
                        <h1 className='mb-4 text-2xl text-white'>Все расходы</h1>
                        <div className='flex flex-col gap-y-2 text-white text-sm items-end'>
                            <span>{(totalExpenses.USDT).toFixed(0)} <span className="bg-black text-xs py-1 px-2 rounded-2xl text-lemon mr-1">USDT</span></span>
                            <span>{(totalExpenses.USD).toFixed(0)} <span className="bg-black text-xs py-1 px-2 rounded-2xl text-lemon mr-1">USD</span></span>
                            <span>Всего: {(totalExpenses.USDT + totalExpenses.USD).toFixed(0)}</span>
                        </div>
                    </div>
            </>
            }
        </div>
        </div>
    </>
    )
}

export default Report;