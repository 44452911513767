import React, {useState, useEffect, useContext} from "react";
import Header from "../components/Header";
import $api from "../http";
import IncomesTable from "../components/Incomes/IncomesTable";
import AddIncome from "../components/Incomes/AddIncome";
import { AuthContext } from "../utils/AuthProvider";
import { SidebarContext } from "../utils/SidebarProvider";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const Income = () => {

	const [managers, setManagers] = useState([]);
	const [incomes, setIncomes] = useState([]);
	const [managersList, setManagersList] = useState([]);
	const { user } = useContext(AuthContext);
	const {menuActive} = useContext(SidebarContext);
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		date: getTodayDate(),
		sum: '',
		exchange: 'USDT',
		comment: '-'
	});

	const [datePeriod, setDatePeriod] = useState([]);

	function getTodayDate() {
		const today = new Date();
		const year = today.getFullYear();
		let month = today.getMonth() + 1;
		if (month < 10) {
			month = '0' + month;
		}
		let day = today.getDate();
		if (day < 10) {
			day = '0' + day;
		}
		return `${year}-${month}-${day}`;
	}

	const getManagers = async () => {
        try{
            const response = await $api.get('/managers/getall');
            setManagers(response.data);
        }catch(error){
            console.log(error.response.data.message);
        }
    }

	const getIncomes = async () => {
        try{
            const response = await $api.get('/income/get', {
                params: {
                    dateRanges: datePeriod.dateRanges
                }
            });
            setIncomes(response.data);
        }catch(error){
            console.log(error.response.data.message);
        }
    }

	const handleAddIncome = async () => {
		const totalPercent = managersList.reduce((sum, manager) => sum + parseFloat(manager.percent), 0);

        if(totalPercent !== 15) {
            alert('Сумма процентов менеджеров должна быть равна 15');
            return;
        }

		if(!formData.date || !formData.sum || !formData.exchange || !formData.comment || managersList.length === 0){
			alert('Заполните все поля');
		}
		else{
			try{
				await $api.post('/income/add', {date: formData.date, sum: formData.sum, exchange: formData.exchange, managers: JSON.stringify(managersList), comment: formData.comment });
				setFormData({
					date: getTodayDate(),
					sum: '',
					exchange: 'USDT',
					comment: '-'
				});
				setManagersList([]);
				getIncomes();
			}catch(error){
				console.log(error.response.data.message);
			}
		}
	}

	const deleteIncome = async (income) => {
		try {
            await $api.delete(`/income/delete/${income.id}`);
            getIncomes();
        }catch(error){
            alert(error.response.data.message);
        }
	}

	useEffect(() => {
		if(user.group === 'Администратор'){
            navigate('/');
        }
        getManagers();
    }, []);

	useEffect(() => {
		if(datePeriod.dateRanges?.length > 0){
            getIncomes();
        }
	}, [datePeriod])

    return (
        <>
			<Sidebar active='income'/>
			<Header />
			<div className={`ml-auto md:px-10 xs:px-5 ${menuActive ? 'w-4/5 2k:px-10' : 'w-full xxl:px-20'}`}>
				<div className='mt-4 mx-auto max-w-[1400px]'>
					<AddIncome managers={managers} handleAddIncome={handleAddIncome} formData={formData} setFormData={setFormData} managersList={managersList} setManagersList={setManagersList}/>
					<IncomesTable incomes={incomes} managers={managers} deleteIncome={deleteIncome} datePeriod={datePeriod} setDatePeriod={setDatePeriod}/>
				</div>
			</div>
      </>
    );
};
  
export default Income;