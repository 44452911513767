import React, {useContext, useEffect, useState} from "react";
import { AuthContext } from '../utils/AuthProvider';
import { Navigate, Outlet } from "react-router-dom";
import $api from "../http";

const PrivateRoute = () => {

    const { user, setUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const checkToken = async () => {
            try {
                if(token && !user){
                    const response = await $api.get('/users/checkToken');
                    if(response.status === 200){
                        setUser(response.data);
                    }
                }
            }catch(error){
                alert(error.response.data.message);
                localStorage.removeItem('token');
                setUser(null);
            }finally{
                setLoading(false);
            }
        };

        checkToken();
    }, [token, user, setUser]);

    if(loading){
        return <div>Loading...</div>;
    }

    return(
        user ? <Outlet /> : <Navigate to='/auth' />
    )

}

export default PrivateRoute;