import React from "react";
import CustomSelect from "../Additional/CustomSelect";
import Input from "../Additional/Input";
import Button from "../Additional/Button";

const AddExpense = (props) => {

    const handleInputChange = (e) => {
		const { name, value } = e.target;
		props.setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

    const handleInputSelect = (name, value) => {
		props.setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

    const options = [
		{ id: 1, value: 'USDT', name: 'USDT' },
		{ id: 2, value: '$', name: '$' }
	  ];

    return(
        <div className='bg-bg-gradient px-6 py-8 w-full rounded-2xl h-fit'>
            <h1 className='mb-4 text-2xl text-white'>Добавить расход</h1>
			<div className='flex items-start gap-x-20 sm:flex-wrap sm:gap-y-4 sm:gap-x-8'>
                <Input data={props.formData.date} setData={handleInputChange} type='date' formName='date' name='Дата' passEvent={true}/>
                <div className='gap-x-4 flex items-end'>
					<Input data={props.formData.sum} setData={handleInputChange} type='number' formName='sum' name='Сумма' className='w-[100px]' passEvent={true}/>
					<CustomSelect options={options} selectedOption={props.formData.exchange} onSelect={handleInputSelect} formName='exchange' value={true} width='120'/>
				</div>
                <div className='flex flex-col'>
                <label className='text-light block pb-2'>Категория</label>
                <CustomSelect options={props.categoryList} selectedOption={props.formData.category.name} onSelect={handleInputSelect} formName='category' label='Категория'/>
                </div>
            </div>
            <Button onClick={props.addExpense} text='Добавить' className='mt-6'/>
        </div>
    )
}

export default AddExpense;