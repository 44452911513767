import React from "react";
import Input from "../Additional/Input";
import Button from "../Additional/Button";

const AddGroup = (props) => {

    return(
        <>
            <div className='bg-bg-gradient px-6 py-8 w-full rounded-2xl mt-4'>
                <h1 className='mb-4 text-2xl text-white'>Добавить команду</h1>

                <div className='flex gap-x-10 mb-6'>
                    <Input data={props.groupName} setData={props.setGroupName} type='text' name='Название команды' />
                </div>
                <Button text='Добавить' onClick={props.addGroup}/>
            </div>
        </>
    )
}

export default AddGroup;