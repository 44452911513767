import React from "react";

const UsersTable = (props) => {
    return(
        <>
            <div className='bg-bg-gradient px-6 py-8 w-1/2 rounded-2xl h-fit sm:w-full'>
                <h1 className='mb-4 text-2xl text-white'>Пользователи</h1>
                <table className='my-table'>
                    <thead>
                    <tr className='border-b-2 border-grey text-light'>
                        <th>ID</th>
                        <th>Логин</th>
                        <th>Команда</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.users?.map((user) => (
                        <tr key={user.id} className='text-center text-white text-sm'>
                            <td>{user.id}</td>
                            <td>{user.login}</td>
                            <td>{user.Group.name}</td>
                            <td onClick={() => props.deleteUser(user)}>
                                <img src='/images/delete.svg' alt="" width={30} className='cursor-pointer'/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default UsersTable;