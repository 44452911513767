import React from "react";

const GroupsTable = (props) => {

    return(
        <>
            <div className='bg-bg-gradient px-6 py-8 w-1/2 rounded-2xl h-fit sm:w-full'>
                <h1 className='mb-4 text-2xl text-white'>Команды</h1>
                <table className='my-table'>
                    <thead>
                    <tr className='border-b-2 border-grey text-light'>
                        <th>ID</th>
                        <th>Название</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.groups?.map((group) => (
                        <tr key={group.id} className='text-center text-white text-sm'>
                            <td>{group.id}</td>
                            <td>{group.name}</td>
                            <td onClick={() => props.deleteGroup(group)}>
                                <img src='/images/delete.svg' alt="" width={30} className='cursor-pointer'/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default GroupsTable;