import React, { useState } from "react";
import Button from "../Additional/Button";
import Input from "../Additional/Input";

const ManagersTable = (props) => {

    const [editMode, setEditMode] = useState(0);
    const [newName, setNewName] = useState('');
    const [modalShow, setModalShow] = useState(false);

    const editManagerName = () => {
        if(newName){
            props.editManager(editMode, newName);
            setEditMode(0);
            setNewName('');
            setModalShow(false);
        }else{
            alert('Измените имя или отмените редактирование');
        }
    }

    const editManager = (manager) => {
        setEditMode(manager.id);
        setNewName(manager.name);
        setModalShow(true);
    }

    const cancelEdit = () => {
        setEditMode(0);
        setModalShow(false);
    }

    return(
        <>
            <div className='bg-bg-gradient px-6 py-8 w-1/2 rounded-2xl h-fit sm:w-full'>
                <h1 className='mb-4 text-2xl text-white'>Мои менеджеры</h1>
                <table className='my-table'>
                    <thead>
                    <tr className='border-b-2 border-grey text-light'>
                        <th>ID</th>
                        <th>Имя</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.managers?.filter(manager => manager.deletedAt === null).map((manager, index) => (
                        <>
                        <tr key={manager.id} className={`text-center text-white text-sm`}>
                            <td>{manager.id}</td>
                            <td>{manager.name}</td>
                            <td className='flex justify-evenly'>
                                <img src='/images/pencil.svg' alt="" width={30} onClick={() => editManager(manager)} className='cursor-pointer'/>
                                <img src='/images/delete.svg' alt="" width={30} onClick={() => props.deleteManager(manager)} className='cursor-pointer'/>
                            </td>
                        </tr>
                        </>                        
                    ))}
                    </tbody>
                </table>
            </div>
            {
                modalShow ? 
                <>
                <div className="fixed inset-0 bg-light bg-opacity-50 z-20"></div>
                <div className="absolute bg-bg-gradient px-6 py-8 w-1/2 rounded-2xl h-fit z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:w-2/3">
                    <h1 className='mb-4 text-2xl text-white'>Редактировать менеджера</h1>
                    <Input data={newName} setData={setNewName} type='text' name='Имя' />
                    <div className='mt-6 flex justify-end gap-x-10'>
                        <button className="text-red" onClick={cancelEdit}>Отменить</button>
                        <Button text='Сохранить' onClick={editManagerName}/>
                    </div>
                </div>
                </>
                :
                <></>
            }
        </>
    )
}

export default ManagersTable;