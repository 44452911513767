import React, { useEffect, useState, useContext } from "react";
import Header from "../components/Header";
import AddExpense from "../components/Expenses/AddExpense";
import ExpensesCategory from "../components/Expenses/ExpensesCategory";
import $api from "../http";
import ExpensesTable from "../components/Expenses/ExpensesTable";
import { AuthContext } from "../utils/AuthProvider";
import { SidebarContext } from "../utils/SidebarProvider";
import Sidebar from "../components/Sidebar";

const Expenses = () => {

    const [categoryInput, setCategoryInput] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const { user } = useContext(AuthContext);
    const {menuActive} = useContext(SidebarContext);

    const [formData, setFormData] = useState({
		date: getTodayDate(),
		sum: '',
		exchange: 'USDT',
		category: ''
	});

    const [datePeriod, setDatePeriod] = useState([]);

	function getTodayDate() {
		const today = new Date();
		const year = today.getFullYear();
		let month = today.getMonth() + 1;
		if (month < 10) {
			month = '0' + month;
		}
		let day = today.getDate();
		if (day < 10) {
			day = '0' + day;
		}
		return `${year}-${month}-${day}`;
	}

    const handleAddCategory = () => {
        if(categoryInput){
            addCategory(categoryInput);
        }else{
            alert('Заполните пустое поле');
        }
    }

    const getCategory = async() => {
        try{
            const response = await $api.get('/expensesCat/get');
            setCategoryList(response.data);
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const addCategory = async(name) => {
        try{
            await $api.post('/expensesCat/add', {name});
            getCategory();
            setCategoryInput('');
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const deleteCategory = async(category) => {
        try{
            await $api.delete(`/expensesCat/delete/${category.id}`);
            getCategory();
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const addExpense = async() => {
        if(!formData.date || !formData.sum || !formData.exchange || !formData.category.id){
			alert('Заполните все поля');
		}
		else{
			try{
				await $api.post('/expense/add', {date: formData.date, sum: parseInt(formData.sum), exchange: formData.exchange, categoryID: formData.category.id });
				setFormData({
                    date: getTodayDate(),
                    sum: '',
                    exchange: '',
                    category: ''
                })
                getExpenses();
			}catch(error){
				console.log(error.response.data.message);
			}
		}
    }

    const getExpenses = async() => {
        try{
            const response = await $api.get('/expense/get', {
                params: {
                    dateRanges: datePeriod.dateRanges
                }
            });
            setExpenses(response.data);
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const deleteExpense = async(expense) => {
        try{
            await $api.delete(`/expense/delete/${expense.id}`);
            getExpenses();
        }catch(error){
            alert(error.response.data.message);
        }
    }

    useEffect(() => {
        getCategory();
    }, []);

    useEffect(() => {
		if(datePeriod.dateRanges?.length > 0 && user.group !== 'Администратор'){
            getExpenses();
        }
	}, [datePeriod])

    return (
        <>
        <Sidebar active='expenses'/>
        <Header />
        <div className={`ml-auto md:px-10 xs:px-5 ${menuActive ? 'w-4/5 2k:px-10' : 'w-full xxl:px-20'}`}>
        <div className='mt-4 mx-auto max-w-[1400px]'>
            <div className={user.group === 'Администратор' ? 'hidden' : 'mx-auto sm:flex-col'}>
                <AddExpense categoryList={categoryList} formData={formData} setFormData={setFormData} addExpense={addExpense}/>
                <ExpensesTable expenses={expenses} deleteExpense={deleteExpense} datePeriod={datePeriod} setDatePeriod={setDatePeriod}/>
            </div>
            <div className={user.group !== 'Администратор' ? 'hidden' : 'mx-auto'}>
                <ExpensesCategory categoryInput={categoryInput} setCategoryInput={setCategoryInput} addCategory={handleAddCategory} categoryList={categoryList} deleteCategory={deleteCategory}/>
            </div>
        </div>
        </div>
        </>
    );
  };
  
export default Expenses;