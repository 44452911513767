import React, { useEffect, useState, useContext } from "react";
import Header from "../components/Header";
import AddUser from "../components/Users/AddUser";
import AddGroup from "../components/Users/AddGroup";
import UsersTable from "../components/Users/UsersTable";
import GroupsTable from "../components/Users/GroupsTable";
import $api from "../http";
import { AuthContext } from "../utils/AuthProvider";
import { SidebarContext } from "../utils/SidebarProvider";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const Users = () => {

    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [groupName, setGroupName] = useState('');
    const { user } = useContext(AuthContext);
    const {menuActive} = useContext(SidebarContext);
    const navigate = useNavigate();

    const getGroups = async () => {
        try{
            const response = await $api.get('/groups/get');
            setGroups(response.data);
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const getUsers = async () => {
        try{
            const response = await $api.get('/users/get');
            setUsers(response.data);
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const addGroup = async () => {
        try{
            if(groupName){
                await $api.post('/groups/add', {name: groupName});
                setGroupName('');
                getGroups();
            }else{
                alert('Заполните пустое поле');
            }
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const addUser = async (login, password, groupID) => {
        try{
            await $api.post('/users/add', {login, password, groupID});
            getUsers();
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const deleteGroup = async (group) => {
        try {
            await $api.delete(`/groups/delete/${group.id}`);
            getGroups();
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const deleteUser = async (user) => {
        try {
            await $api.delete(`/users/delete/${user.id}`);
            getUsers();
        }catch(error){
            alert(error.response.data.message);
        }
    }

    useEffect(() => {
        if(user.group !== 'Администратор'){
            navigate('/');
        }
        getGroups();
        getUsers();
    }, []);


    return(
        <>
            <Sidebar active='users'/>
            <Header />
            <div className={`ml-auto md:px-10 xs:px-5 ${menuActive ? 'w-4/5 2k:px-10' : 'w-full xxl:px-20'}`}>
                <div className='mx-auto max-w-[1400px]'>
                    <AddUser groups={groups} addUser={addUser}/>
                    <AddGroup groupName={groupName} setGroupName={setGroupName} addGroup={addGroup}/>
                    <div className='mt-4 mb-20 flex gap-x-6 sm:flex-col sm:gap-y-4 sm:mb-10'>
                        <UsersTable users={users} deleteUser={deleteUser}/>
                        <GroupsTable groups={groups} deleteGroup={deleteGroup}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users;