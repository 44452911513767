import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../utils/AuthProvider";
import { SidebarContext } from "../utils/SidebarProvider";

const Sidebar = (props) => {

    const { user } = useContext(AuthContext);
    const {menuActive, setMenuActive} = useContext(SidebarContext);

    return (
        <>
            <div className={`min-h-[96vh] w-1/5 bg-bg-gradient pt-4 px-4 rounded-r-[40px] fixed lg:invisible ${menuActive ? '' : 'hidden'}`}>
                <div className='flex justify-between items-center border-b border-gray pb-10 pt-6'>
                    <h1 className='text-2xl text-white flex'><img className='mr-2' src='/images/logo.svg' alt=""/>CryptoCRM</h1>
                    <img src="/images/close.svg" alt="" className="rotate-45 cursor-pointer" onClick={() => setMenuActive(false)} width={24}/>
                </div>
                <div className='mt-10 flex flex-col'>
                    <Link to="/" className={`flex rounded-2xl px-4 py-2 ${props.active === 'dashboard' ? 'bg-black text-lemon' : 'text-white'} `}><img className='mr-4' src="/images/home.svg" alt=""/>Главная</Link>
                    <Link to="/users" className={`flex rounded-2xl px-4 py-2 ${props.active === 'users' ? 'bg-black text-lemon' : 'text-white'} ${user.group !== 'Администратор' ? 'hidden' : ''}`}><img className='mr-4' src="/images/users.svg" alt=""/>Пользователи</Link>
                    <Link to="/managers" className={`flex rounded-2xl px-4 py-2 ${props.active === 'managers' ? 'bg-black text-lemon' : 'text-white'} ${user.group === 'Администратор' ? 'hidden' : ''}`}><img className='mr-4' src="/images/managers.svg" alt=""/>Менеджеры</Link>
                    <Link to="/income" className={`flex rounded-2xl px-4 py-2 ${props.active === 'income' ? 'bg-black text-lemon' : 'text-white'} ${user.group === 'Администратор' ? 'hidden' : ''}`}><img className='mr-4' src="/images/income.svg" alt=""/>Доходы</Link>
                    <Link to="/expenses" className={`flex rounded-2xl px-4 py-2 ${props.active === 'expenses' ? 'bg-black text-lemon' : 'text-white'}`}><img className='mr-4' src="/images/costs.svg" alt=""/>Расходы</Link>
                    <Link to="/report" className={`flex rounded-2xl px-4 py-2 ${props.active === 'report' ? 'bg-black text-lemon' : 'text-white'} ${user.group !== 'Администратор' ? 'hidden' : ''}`}><img className='mr-4' src="/images/report.svg" alt=""/>Отчет</Link>
                </div>
            </div>
            <div className={`lg:invisible absolute mt-3 ml-3 ${!menuActive ? '' : 'hidden'}`}>
                <img src='/images/menu.svg' alt="" className="cursor-pointer" width={40} onClick={() => setMenuActive(true)}/>
            </div>

            <div className={`invisible lg:visible min-h-[100vh] bg-bg-gradient pt-4 px-4 fixed w-full -mt-4 z-50 ${menuActive ? '' : 'hidden'}`}>
                <div className='flex justify-between items-center border-b border-gray pb-10 pt-6 lg:w-1/3 lg:mx-auto sm:w-1/2'>
                    <h1 className='text-2xl text-white flex'><img className='mr-2' src='/images/logo.svg' alt=""/>CryptoCRM</h1>
                    <img src="/images/close.svg" alt="" className="rotate-45 cursor-pointer" onClick={() => setMenuActive(false)} width={24}/>
                </div>
                <div className='mt-10 flex flex-col lg:w-1/3 lg:mx-auto sm:w-1/2'>
                    <Link to="/" className={`flex rounded-2xl px-4 py-2 ${props.active === 'dashboard' ? 'bg-black text-lemon' : 'text-white'} `} onClick={() => setMenuActive(false)}><img className='mr-4' src="/images/home.svg" alt=""/>Главная</Link>
                    <Link to="/users" className={`flex rounded-2xl px-4 py-2 ${props.active === 'users' ? 'bg-black text-lemon' : 'text-white'} ${user.group !== 'Администратор' ? 'hidden' : ''}`} onClick={() => setMenuActive(false)}><img className='mr-4' src="/images/users.svg" alt=""/>Пользователи</Link>
                    <Link to="/managers" className={`flex rounded-2xl px-4 py-2 ${props.active === 'managers' ? 'bg-black text-lemon' : 'text-white'} ${user.group === 'Администратор' ? 'hidden' : ''}`} onClick={() => setMenuActive(false)}><img className='mr-4' src="/images/managers.svg" alt=""/>Менеджеры</Link>
                    <Link to="/income" className={`flex rounded-2xl px-4 py-2 ${props.active === 'income' ? 'bg-black text-lemon' : 'text-white'} ${user.group === 'Администратор' ? 'hidden' : ''}`} onClick={() => setMenuActive(false)}><img className='mr-4' src="/images/income.svg" alt=""/>Доходы</Link>
                    <Link to="/expenses" className={`flex rounded-2xl px-4 py-2 ${props.active === 'expenses' ? 'bg-black text-lemon' : 'text-white'}`} onClick={() => setMenuActive(false)}><img className='mr-4' src="/images/costs.svg" alt=""/>Расходы</Link>
                    <Link to="/report" className={`flex rounded-2xl px-4 py-2 ${props.active === 'report' ? 'bg-black text-lemon' : 'text-white'} ${user.group !== 'Администратор' ? 'hidden' : ''}`} onClick={() => setMenuActive(false)}><img className='mr-4' src="/images/report.svg" alt=""/>Отчет</Link>
                </div>
            </div>
            <div className={`invisible lg:visible absolute mt-3 ml-3 ${!menuActive ? '' : 'hidden'}`}>
                <img src='/images/menu.svg' alt="" className="cursor-pointer" width={40} onClick={() => setMenuActive(true)}/>
            </div>
        </>
    );
  };
  
export default Sidebar;