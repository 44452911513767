import React, {useEffect, useState, useRef} from "react";
import CustomSelect from "../Additional/CustomSelect";
import Input from "../Additional/Input";
import Button from "../Additional/Button";

const AddIncome = (props) => {

	const [candidat, setCandidat] = useState({name: ''});
	const [percent, setPercent] = useState(7.5);
	const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
	const [filteredManagers, setFilteredManagers] = useState(props.managers);
	const dropdownRef = useRef(null);

	const options = [
		{ id: 1, value: 'USDT', name: 'USDT' },
		{ id: 2, value: '$', name: '$' }
	  ];
	
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		props.setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	const handleInputSelect = (name, value) => {
		props.setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

    const addManagerToList = () => {
		if(candidat.name && candidat.id && percent){
			if(searchTerm !== candidat.name){
				alert('Выберите менеджера из выпадающего списка');
			}else if(props.managersList.some(manager => candidat.id === manager.id)){
				alert('Менеджер с таким именем уже добавлен');
			}else{
				props.setManagersList(prevManagersList => [
					...prevManagersList,
					{ id: candidat.id, name: candidat.name, percent, groupID: candidat.groupID}
				]);
				setCandidat({name: ''});
				setSearchTerm('');
			}
		}else{
			alert('Заполните пустые поля');
		}
	}

	const deleteManagerFromList = (managerToDelete) => {
		props.setManagersList(prevManagersList =>
			prevManagersList.filter(manager => manager !== managerToDelete)
		);
	}

	useEffect(() => {
		setFilteredManagers(props.managers?.filter(manager => 
			manager.deletedAt === null && manager.name.toLowerCase().includes(searchTerm.toLowerCase())
		));
	}, [searchTerm, props.managers])

	useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

	const findManager = (value) => {
		setSearchTerm(value);
	}

    return(
        <div className='bg-bg-gradient px-6 py-8 w-full rounded-2xl'>
			<h1 className='mb-4 text-2xl text-white'>Добавить результат</h1>
			<div className='flex items-start gap-x-14 xl:flex-wrap xl:mb-4 xl:gap-y-4'>
				<Input data={props.formData.date} setData={handleInputChange} type='date' formName='date' name='Дата' passEvent={true}/>
				<div className='gap-x-4 flex items-end'>
					<Input data={props.formData.sum} setData={handleInputChange} type='number' formName='sum' name='Сумма' className='w-[100px]' passEvent={true}/>
					<CustomSelect options={options} selectedOption={props.formData.exchange} onSelect={handleInputSelect} formName='exchange' value={true} width='120'/>
				</div>
				<div>
					<div className="flex items-end mb-2">
						<div ref={dropdownRef} className="mr-2">
							<Input data={searchTerm} setData={findManager} type="text" name='Добавить менеджера' placeholder="Поиск менеджера..." width={200} onClick={() => setShowDropdown(true)}/>
							{showDropdown && filteredManagers.length > 0 && (
							<div className='absolute inline-block w-inherit mt-[1px] w-[200px]'>
								<div className="absolute inset-0 rounded-2xl bg-gradient" />
								<ul className="dropdown bg-bg-gradient rounded-2xl p-2 z-20 text-white relative m-[1px]">
								{filteredManagers.map((manager) => (
									<li 
										key={manager.id}
										onClick={() => {
											setCandidat({ 
												name: manager.name, 
												id: manager.id, 
												groupID: manager.groupID 
											});
											setSearchTerm(manager.name);
											setShowDropdown(false);
											
										}}
										className="py-1 px-6 cursor-pointer hover:text-lemon hover:bg-dark rounded-2xl w-full"
									>
										{manager.name}
									</li>
								))}
							</ul>
							</div>
						)}
						</div>
						<Input type="number" data={percent} setData={setPercent} width={50} className='text-center'/>
					</div>
					{props.managersList?.map((manager, index) => (
						<div className='flex items-center'>
							<span className="text-xs text-black bg-white px-2 py-1 rounded-2xl mr-2 font-medium">{manager.percent}%</span>
							<span className='text-sm py-1 text-light'>{manager.name}</span>
							<img className='ml-2 cursor-pointer' src='/images/delete.svg' alt="" onClick={() => deleteManagerFromList(manager)}/>
						</div>
					))}
					<button className='p-2 rounded-full bg-lemon mt-2 hover:bg-lemonHover' onClick={addManagerToList}>
						<img src="/images/add.svg" alt="" />
					</button>
				</div>
				<Input type="text" name="Коментарий" formName='comment' data={props.formData.comment} setData={handleInputChange} passEvent={true}/>
			</div>
			<Button onClick={props.handleAddIncome} text='Добавить' className=''/>
		</div>
    )
}

export default AddIncome;