import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

const Dashboard = () => {

    return (
        <>
          <Sidebar active='dashboard'/>
          <Header />
        </>
    );
  };
  
export default Dashboard;