import React, { useEffect, useState, useContext } from "react";
import Header from "../components/Header";
import AddManager from "../components/Managers/AddManager";
import ManagersTable from "../components/Managers/ManagersTable";
import $api from "../http/index";
import { AuthContext } from "../utils/AuthProvider";
import { SidebarContext } from "../utils/SidebarProvider";
import { useNavigate } from "react-router-dom";
import ManagersSalary from "../components/Managers/ManagersSalary";
import Sidebar from "../components/Sidebar";

const Managers = () => {

    const [managers, setManagers] = useState([]);
    const { user } = useContext(AuthContext);
    const {menuActive} = useContext(SidebarContext);
    const navigate = useNavigate();

    const getManagers = async () => {
        try{
            const response = await $api.get('/managers/get');
            setManagers(response.data);
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const addManager = async (name, group) => {
        try{
            await $api.post('/managers/add', {name, group});
            getManagers();
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const deleteManager = async (manager) => {
        try {
            await $api.delete(`/managers/delete/${manager.id}`);
            getManagers();
        }catch(error){
            alert(error.response.data.message);
        }
    }

    const editManager = async(managerId, newName) => {
        const findManager = managers.filter((manager) => manager.id === managerId);
        if(findManager[0].name !== newName){
            try{
                await $api.patch(`/managers/editName/${managerId}`, {newName});
                getManagers();
            }catch(error){
                alert(error.response.data.message);
            }
        }
    }

    useEffect(() => {
        if(user.group === 'Администратор'){
            navigate('/');
        }
        getManagers();
    }, []);

    return(
        <>
            <Sidebar active='managers' />
            <Header />
            <div className={`ml-auto md:px-10 xs:px-5 ${menuActive ? 'w-4/5 2k:px-10' : 'w-full xxl:px-20'}`}>
            <div className='mx-auto max-w-[1400px]'>
                <AddManager addManager={addManager} />
                <div className='mt-4 mb-20 flex gap-x-6 sm:flex-col sm:gap-y-4 sm:mb-10'>
                    <ManagersTable managers={managers} deleteManager={deleteManager} editManager={editManager} />
                    <ManagersSalary managers={managers} />
                </div>
            </div>
            </div>
        </>
    )
}

export default Managers;