import React, { useState } from "react";
import Button from "../Additional/Button";
import Input from "../Additional/Input";

const AddManager = (props) => {

    const [name, setName] = useState();

    const addManager = () => {
        if(name){
            props.addManager(name);
            setName('');
        }else{
            alert('Заполните пустые поля');
        }
    }

    return(
        <>
            <div className='bg-bg-gradient px-6 py-8 mt-4 w-full rounded-2xl'>
                <h1 className='mb-4 text-2xl text-white'>Добавить менеджера</h1>
                <Input data={name} setData={setName} type='text' name='Имя менеджера' />
                <Button text='Добавить' onClick={addManager} className='mt-6'/>
            </div>
        </>
    )
}

export default AddManager;