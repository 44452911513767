import React, { useEffect, useState, useRef } from 'react';
import { startOfWeek, endOfWeek, subWeeks, format } from 'date-fns';

const WeekSelect = (props) => {

    const [selectMenu, setSelectMenu] = useState(false);
    const [selectedDate, setSelectedDate] = useState([]);
    const wrapperRef = useRef(null);

    const currentDate = new Date();
    const startDateOfThisWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
    const endDateOfThisWeek = endOfWeek(currentDate, { weekStartsOn: 1 });

    const thisWeek = {
        startDate: startDateOfThisWeek,
        endDate: endDateOfThisWeek
    };

    const previousWeeks = Array.from({ length: 10 }, (_, index) => ({
        startDate: startOfWeek(subWeeks(currentDate, index + 1), { weekStartsOn: 1 }),
        endDate: endOfWeek(subWeeks(currentDate, index + 1), { weekStartsOn: 1 })
    }));

    const formattedOptions = previousWeeks.map((week) => ({
        label: `${format(week.startDate, 'dd-MM-yyyy')} - ${format(week.endDate, 'dd-MM-yyyy')}`,
        value: `${format(week.startDate, 'yyyy-MM-dd')},${format(week.endDate, 'yyyy-MM-dd')}`
    }));

    function sortByKey(array) {
        return array.sort((a, b) => {
            if (a.start < b.start) {
                return -1;
            }
            if (a.start > b.start) {
                return 1;
            }
            return 0;
        });
    }

    useEffect(() => {
        setSelectedDate([{start: format(thisWeek.startDate, 'yyyy-MM-dd'), stop: format(thisWeek.endDate, 'yyyy-MM-dd')}])
    }, [])

    useEffect(() => {
        props.setInputs(prevState => ({
            ...prevState,
            dateRanges: sortByKey(selectedDate)
        }));
    }, [selectedDate]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setSelectMenu(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const selectDate = (event) => {
        const selectedDates = event.target.value.split(',');
        const newStart = selectedDates[0];
        const newStop = selectedDates[1];
        const existingIndex = selectedDate.findIndex(item => item.start === newStart && item.stop === newStop);
        
        if(existingIndex !== -1){
            setSelectedDate(prevState => {
                const updatedArray = [...prevState];
                updatedArray.splice(existingIndex, 1);
                return sortByKey(updatedArray);
            });
        }else{
            setSelectedDate(prevState => {
                const updatedArray = [
                    ...prevState,
                    { start: newStart, stop: newStop }
                ];
                return sortByKey(updatedArray);
            });
        }
    }

    return (
        <>
            <button onClick={() => setSelectMenu(!selectMenu)} className='relative inline-block w-fit'>
                <div className="absolute inset-0 rounded-2xl bg-gradient" />
                <button
                    className="bg-dark rounded-2xl text-white px-4 py-1 relative z-10 m-[1px] flex"
                >
                    <img className='mr-1' src='images/date.svg' alt='' />
                    {selectedDate.length > 0
                    ? format(selectedDate[0]?.start, 'dd-MM-yyyy') + ' - ' + format(selectedDate[selectedDate.length - 1]?.stop, 'dd-MM-yyyy')
                    : 'Выберите дату'}
                </button>
            </button>
            <div className={`absolute w-fit ${props.className}`}>
                <div className="absolute inset-0 rounded-2xl bg-gradient" />
                <div className={`bg-bg-gradient rounded-2xl p-4 z-20 text-white relative m-[1px] ${selectMenu ? '' : 'hidden'}`} ref={wrapperRef}>
                    <div>
                    <input
                        type='checkbox'
                        defaultChecked={true}
                        value={`${format(thisWeek.startDate, 'yyyy-MM-dd')},${format(thisWeek.endDate, 'yyyy-MM-dd')}`}
                        onClick={selectDate}
                    />
                    <span className='ml-2'>
                        {`${format(thisWeek.startDate, 'dd-MM-yyyy')} - ${format(thisWeek.endDate, 'dd-MM-yyyy')}`}
                    </span>
                    </div>
                    {formattedOptions.map(option => (
                    <div key={option.value}>
                        <input type='checkbox' value={option.value} onClick={selectDate} />
                        <span className='ml-2'>{option.label}</span>
                    </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default WeekSelect;