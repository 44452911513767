import React, { useState, useEffect } from "react";
import WeekSelect from "../WeekSelect";

const ExpensesTable = (props) => {

    const [totalExpenses, setTotalExpenses] = useState({
        USDT: 0,
        USD: 0
    });

    function calculateTotalExpenses(expenses, exchange) {
        return expenses.reduce((total, expense) => {
            if (expense.exchange === exchange) {
                return total + expense.sum;
            }
            return total;
        }, 0);
    }

    useEffect(() => {
        setTotalExpenses(prevState => ({
			...prevState,
			'USDT': calculateTotalExpenses(props.expenses, 'USDT'),
            'USD': calculateTotalExpenses(props.expenses, '$')
		}));
    }, [props.expenses]);

    return(
        <>
        <div className='bg-bg-gradient px-6 py-8 w-full rounded-2xl h-fit mt-4'>
                <h1 className='mb-4 text-2xl text-white'>Последние расходы</h1>
                <WeekSelect inputs={props.datePeriod} setInputs={props.setDatePeriod}/>
                <div className="overflow-x-auto">
                <table className='my-table mt-4'>
                    <thead>
                        <tr className='border-b-2 border-grey text-light'>
                            <th className='xs:px-5'>Дата</th>
                            <th className='xs:px-5'>Сумма</th>
                            <th className='xs:px-5'>Категория</th>
                            <th className='xs:px-5'></th>
                        </tr>
                    </thead>
                    <tbody>
                    {props.expenses?.map((expense, index) => (
                        <tr key={index} className='text-center text-white text-sm'>
                            <td className="text-nowrap">{expense.date}</td>
                            <td>{expense.sum} {expense.exchange}</td>
                            <td>{expense.ExpensesCat.name}</td>
                            <td>
                                <img src='/images/delete.svg' alt='' width={30} className='cursor-pointer' onClick={() => props.deleteExpense(expense)}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                </div>
            </div>

            <div className='w-full ml-auto mx-10 mt-4 mb-20'>
            <div className='bg-bg-gradient px-6 py-8 rounded-2xl h-fit flex justify-between'>
                <h1 className='mb-4 text-2xl text-white'>Все расходы</h1>
                <div className='flex flex-col gap-y-2 text-white text-sm items-end'>
                    <span>{(totalExpenses.USDT).toFixed(0)} <span className="bg-black text-xs py-1 px-2 rounded-2xl text-lemon mr-1">USDT</span></span>
                    <span>{(totalExpenses.USD).toFixed(0)} <span className="bg-black text-xs py-1 px-2 rounded-2xl text-lemon mr-1">USD</span></span>
                    <span>Всего: {(totalExpenses.USDT + totalExpenses.USD).toFixed(0)}</span>
                </div>
            </div>
            </div>
            </>
    )
}

export default ExpensesTable;