import React from "react";
import Button from "../Additional/Button";
import Input from "../Additional/Input";

const ExpensesCategory = (props) => {

    return(
        <div className='w-full ml-auto mt-4 mb-20 flex gap-x-6 md:flex-col-reverse md:gap-y-4'>
            <div className='bg-bg-gradient px-6 py-8 w-1/2 rounded-2xl h-fit md:w-full'>
                <h1 className='mb-4 text-2xl text-white'>Категории расходов</h1>
                <table className='my-table'>
                    <thead>
                    <tr className='border-b-2 border-grey text-light'>
                        <th>ID</th>
                        <th>Название</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.categoryList?.map((category) => (
                        <tr key={category.id} className='text-center text-white text-sm'>
                            <td>{category.id}</td>
                            <td>{category.name}</td>
                            <td onClick={() => props.deleteCategory(category)}>
                                <img src='/images/delete.svg' alt="" width={30} className='cursor-pointer'/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className='bg-bg-gradient px-6 py-8 w-1/2 rounded-2xl h-fit md:w-full'>
                <h1 className='mb-4 text-2xl text-white'>Добавить категорию расходов</h1>
                <Input data={props.categoryInput} setData={props.setCategoryInput} name='Название' type='text'/>
                <Button text='Добавить' onClick={props.addCategory} className='mt-6'/>
            </div>
        </div>
    )
}

export default ExpensesCategory;