import React, { useContext } from "react";
import { AuthContext } from "../utils/AuthProvider";
import { SidebarContext } from "../utils/SidebarProvider";

const Header = (props) => {

    const { user, setUser } = useContext(AuthContext);
    const {menuActive} = useContext(SidebarContext);

    const logout = () => {
        setUser(null);
        localStorage.removeItem('token');
    }

    return (
        <>
            <div className={`ml-auto md:pr-10 md:pl-16 xs:pr-5 ${menuActive ? 'w-4/5 2k:px-10' : 'w-full xxl:px-20'}`}>
                <div className={`px-4 py-2 bg-bg-gradient rounded-2xl mt-4 mx-auto max-w-[1400px]`}>
                    <div className='flex justify-between items-center'>
                        {user ? <span className='mr-4 text-white flex items-center'><img src="/images/icon.svg" alt="" className='mr-4'/> {user.login}</span> : <></>}
                        <img src="/images/logout.svg" alt="" className='bg-red rounded-full p-2 h-fit cursor-pointer' onClick={logout}/>
                    </div>
                </div>
            </div>
        </>
    );
  };
  
export default Header;