import React, { useState, useContext } from "react";
import { AuthContext } from '.././utils/AuthProvider';
import { useNavigate } from "react-router-dom";
import $api from "../http";
import Input from "../components/Additional/Input";

const Auth = () => {

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const { setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const authUser = async () => {
        try{
            if(login && password){
                const response = await $api.post('/users/auth', {login, password});
                    setUser({login: response.data.login, group: response.data.group, groupID: response.data.groupID});
                    localStorage.setItem('token', response.data.token);
                    navigate('/');
            }else{
                alert('Заполните пустые поля');
            }
        }catch(err){
            alert(err.response.data.message);
        }
    }

    return (
        <>
            <div className='w-full flex items-center justify-center min-h-screen'>
                <div className='bg-bg-gradient py-8 px-16 rounded-2xl'>
                    <h1 className='text-center font-bold text-2xl text-white'>Crypto CRM</h1>
                    <div className='flex flex-col mt-8 text-white gap-y-4'>
                        <Input data={login} setData={setLogin} type='text' name='Логин'/>
                        <Input data={password} setData={setPassword} type='password' name='Пароль'/>
                        <button className='text-black mt-8 w-full py-2 bg-lemon rounded-lg font-bold uppercase hover:bg-lemonHover' onClick={authUser}>Войти</button>
                    </div>
                </div>
            </div>
        </>
    );
  };
  
export default Auth;