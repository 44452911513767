import React from 'react';

const Input = ({ data, setData, type, name, formName, placeholder, width, className, onClick, passEvent }) => {
  const handleChange = (e) => {
    passEvent ? setData(e) : setData(e.target.value);
  };

  return (
    <div className='flex flex-col'>
      <label className='text-light'>{name}</label>
      <div className="relative inline-block w-fit mt-2">
        <div className="absolute inset-0 rounded-2xl bg-gradient" />
        <input
          name={formName}
          placeholder={placeholder}
          type={type}
          className={`bg-dark px-2 py-1 rounded-2xl relative z-10 m-[1px] text-white w-[${width}px] ${className}`}
          value={data}
          onChange={handleChange}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default Input;